<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <form
          @submit.prevent="(page = 0), getCustomerQuote()"
          @keydown.enter="(page = 0), getCustomerQuote()"
        >
          <div class="filter-card">
            <div class="filter-field">
              <label>客戶查詢</label>
              <input type="text" placeholder="請輸入關鍵字" v-model="keyword" />
            </div>
            <button type="submit" class="btn btn-primary btn-sm ml-1">
              查詢
            </button>
            <button type="button" class="btn btn-warning btn-sm ml-2" @click="goBack">
              返回
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="page-body">
      <div class="table-wrap">
        <el-table :data="customerList" :header-cell-style="{fontWeight: 600}" style="width: 100%">
          <el-table-column align="center" width="90px">
            <template>
              <img width="40" src="~@/assets/img/custom_head.png" alt="" />
            </template>
          </el-table-column>
          <el-table-column align="center" width="30px" />
          <el-table-column prop="cusT_SNM" label="客戶名稱" />
          <el-table-column prop="cusT_NOX" label="客戶編號" />
          <el-table-column prop="weeK_DAYS" label="可送貨日" />
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                data-toggle="modal"
                data-target="#deliveryDateModal"
                @click="
                  (chooseCustomer = scope.row),
                    (chooseWeekday = JSON.parse(
                      JSON.stringify([...scope.row.weeK_DAYS.split(',')])
                    ))
                "
              >
                修改可送貨日
              </button>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="goProductQuotePage(scope.row)"
              >
                選擇
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>

        <!-- 分頁 -->
        <el-pagination
          v-show="count > 0"
          layout="prev, pager, next"
          :total="count"
          @current-change="changePage"
        />
      </div>
    </div>

    <!-- 可送貨日 Modal -->
    <div
      class="modal fade"
      id="deliveryDateModal"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5>請選取欲設定之可送貨日</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="delivery-date-list">
              <label v-for="(item, index) in weekday" :key="index">
                <input type="checkbox" :value="item" v-model="chooseWeekday" />
                <span>{{ item }}</span>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              關閉
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="changeWeekday"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import { apiGetCustomerQuote, apiUpdateWeekday } from "@/apis/quote";

export default {
  name: "CustomerQuote",
  data() {
    return {
      // 關鍵字
      keyword: "",
      // 客戶列表
      customerList: [],
      // 客戶總數
      count: 0,
      // 分頁
      page: 0,

      // 所選取客戶資料
      chooseCustomer: [],
      // 所選取可送貨日
      chooseWeekday: [],

      weekday: ["日", "一", "二", "三", "四", "五", "六"]
    };
  },
  methods: {
    // 選擇客戶跳轉至商品報價頁面
    goProductQuotePage(customer) {
      const customerQuote = {
        cusT_ID: customer.cusT_ID,
        cusT_NOX: customer.cusT_NOX,
        stoR_CDX: customer.stoR_CDX,
        docX_TYP: customer.docX_TYP,
        docX_NOX: customer.docX_NOX
      };
      localStorage.setItem("CUSTOMER_QUOTE", JSON.stringify(customerQuote));
      this.$router.push({ path: "product-quote" });
    },

    // 變更可送貨日
    changeWeekday() {
      const weeK_DAYS = this.weekday
        .map(ele => {
          return this.chooseWeekday.indexOf(ele) > -1 ? "1" : "0";
        })
        .toString();
      apiUpdateWeekday({
        ...this.chooseCustomer,
        weeK_DAYS: weeK_DAYS
      })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              title: "成功",
              message: "可送貨日已變更",
              type: "success",
              duration: 2500
            });
            this.getCustomerQuote();
          }
        });
    },

    // 返回
    goBack() {
      localStorage.removeItem("CUSTOMER_QUOTE");
      this.$router.push({ path: "select-customer" });
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getCustomerQuote();
    },

    // 取得報價客戶
    getCustomerQuote() {
      this.updateLoading(true);
      apiGetCustomerQuote({
        salE_MAN: localStorage.USER_GUID,
        keyword: this.keyword,
        pageIndex: this.page
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.count = response.data.count;
            this.customerList = response.data.datas.map(item => {
              return {
                cusT_ID: item.cusT_ID,
                cusT_SNM: item.cusT_SNM,
                cusT_NOX: item.cusT_NOX,
                stoR_CDX: item.stoR_CDX,
                docX_NOX: item.docX_NOX,
                docX_TYP: item.docX_TYP,
                weeK_DAYS: this.weekday
                  .filter((ele, index) => {
                    return [...item.weeK_DAYS.split(",")][index] === "1";
                  })
                  .toString()
              };
            });
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  created() {
    this.getCustomerQuote();
    localStorage.setItem("QUOTATION", JSON.stringify([]));
  }
};
</script>

<style lang="scss" scoped>
.delivery-date-list {
  label {
    display: block;
    margin-bottom: 10px;
    letter-spacing: 2px;
    cursor: pointer;

    span {
      &::before {
        content: "✓";
        color: #fff;
        background-color: #ccc;
        width: 20px;
        height: 20px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }
    input {
      display: none;

      &:checked {
        & + span {
          &::before {
            background-color: #e60012;
          }
        }
      }
    }
  }
}

/deep/ .el-table, .el-table__expanded-cell {
  font-weight: 600;
}
</style>
