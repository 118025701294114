import { render, staticRenderFns } from "./CustomerQuote.vue?vue&type=template&id=896b29aa&scoped=true&"
import script from "./CustomerQuote.vue?vue&type=script&lang=js&"
export * from "./CustomerQuote.vue?vue&type=script&lang=js&"
import style0 from "./CustomerQuote.vue?vue&type=style&index=0&id=896b29aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "896b29aa",
  null
  
)

export default component.exports